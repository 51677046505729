import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@liquid-state/ui-kit';

function selectValue(value, selected, all) {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value, selected) {
  return selected.filter(v => v !== value);
}

function CheckboxesWidget(props) {
  const {
    id,
    disabled,
    options,
    value,
    autofocus,
    readonly,
    onChange,
  } = props;
  const { enumOptions, inline } = options;
  return (
    <div className="checkboxes" id={id}>
      {enumOptions.map((option, index) => {
        const checked = value.indexOf(option.value) !== -1;
        const disabledCls = disabled || readonly ? 'disabled' : '';
        const checkbox = (
          <span>
            <Checkbox
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={(event) => {
                const all = enumOptions.map(item => item.value);
                if (event.target.checked) {
                  onChange(selectValue(option.value, value, all));
                } else {
                  onChange(deselectValue(option.value, value));
                }
              }}
            />
            <span>{option.label}</span>
          </span>
        );
        return inline ? (
          <div className={`checkbox-inline ${disabledCls}`}>
            {checkbox}
          </div>
        ) : (
          <div className={`checkbox ${disabledCls}`}>
            <div>{checkbox}</div>
          </div>
        );
      })}
    </div>
  );
}

CheckboxesWidget.defaultProps = {
  autofocus: false,
};

if (process.env.NODE_ENV !== 'production') {
  CheckboxesWidget.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      enumOptions: PropTypes.array,
      inline: PropTypes.bool,
    }).isRequired,
    value: PropTypes.string,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  CheckboxesWidget.defaultProps = {
    value: '',
    readonly: false,
    disabled: false,
  };
}

export default CheckboxesWidget;
